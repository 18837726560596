import {
  getSlotSizes,
  getSlotLevelTargeting,
  getResponsiveMappings,
  getFloorPrice,
  chooseCohort,
  stickyAdSetup,
  getPublisherProvidedId,
} from './utils';
import { sendAdserverRequest, setServiceLevelTargeting } from './gam';
import { setupHeaderBidding, executeAuction } from './headerBidding';
import { getAdObserver, refreshAd, getRefreshObserver } from './refresh';
import addSidebarAds from './dynamic-desktop';
import { addInlineMobileAds } from './dynamic-mobile';
import { addDesktopCommercialSlots } from './commercial-slots';
import { debugInit, debugAdEvent } from './debug';

export default class BrockmanAds {
  constructor(adClass, options, cohorts) {
    this.adSelector = adClass;
    this.adClass = adClass.replace('.', '');

    const defaults = {
      initialized: false,

      // misc
      baseUri: '',
      device: 'mobile',
      ownedSite: true,
      countryCode: '',
      floorPrices: {},
      debug:
        new URLSearchParams(document.location.search).get('ads-debug') ===
        'true',
      testingCohort: '',
      advertHeader: 'Advertisement',
      useArticleVideoLayout: 'false',
      articleType: '',
      articlePublishedDate: '',
      runAutoSlotsEverywhere: false,
      permutiveTimeout: 0,
      userExternalUuid: '',
      adObserverViewports: 1.8,
      adObserverMobileMultiplier: 2.0,
      lazyloadDefaultSpacer: 800,
      lazyloadSpacerMobile: 800,

      // gam
      gamId: '43340684',
      childPublisher: '',
      customVariables: '',
      inlineInto: '',
      desktopInlineInto: '.article_body_content',
      showNonPersonalisedAds: 'false',

      // ad units desktop
      insertInto: '',
      insertTopMargin: 0,
      appendSidebarAds: true,
      stickySidebar: false,
      desktopSticky: '',
      stickyViewports: 3,
      dynamicAdDesktop: '',

      // ad units mobile
      stickyAd: '',
      dynamicAd: 'AE_MPU_1',
      inlineAdsConfig: {
        includeHeaderInCalc: true,
        minAdvertSpacing: 0,
        lastAdTollerance: 1,
      },
      inlineBetweenTextOnly: false,
      lowLeaderboard: 'AE_LB2',
      firstMobileAd: '',
      fixMobileAdHeight: false,
      mobileHalfpagePosition: 0,
      viewportMultiplierMobile: 1,

      // video
      primisPlaylistPlacementId: '',
      primisStyling: false,
      videoPlaylistTitle: '',
      videoPlaylist: false,
      articleVideoAutoInject: true,
      videoPlaylistArticles: false,

      // polls
      runQuokkster: false,
      articleAutoPoll: true,

      // header bidding
      biddingTimeout: 2000,
      runPrebid: false,
      prebidConfig: {},
      prebidTimeout: 1500,
      runUAM: false,
      pubUAM: 'a06ac1f9-0844-4cf7-81fe-f8ce4a7d7943',
      runAnonymised: false,

      // refresh
      refreshAdsConfig: {
        enabled: false,
        maxRefreshes: 10,
        desktopRate: 30000,
        mobileRate: 30000,
        mobileStickyRate: 30000,
        teadsRate: 30000,
        conversantRate: 30000,
        refreshableAdvertIds: [220082044, 78946204, 72863404],
        refreshDirect: false,
        directMobileRate: 30000,
        threshold: 0.5, 
      },
    };

    if (cohorts !== undefined && cohorts.length > 1) {
      const chosenCohort = chooseCohort(cohorts);

      Object.keys(chosenCohort).forEach((prop) => {
        options[prop] = chosenCohort[prop];
      });

      const metaEl = document.createElement('meta');
      metaEl.name = 'pbstck_context:pbstck_ab_test';
      metaEl.content = chosenCohort.testingCohort;
      document.getElementsByTagName('head')[0].appendChild(metaEl);
    }

    const opts = { ...defaults, ...options };

    Object.keys(defaults).forEach((prop) => {
      this[prop] = opts[prop];
    });

    if (this.childPublisher) {
      this.gamId += `,${this.childPublisher}`;
    }

    this.GAMFloor = getFloorPrice(this);
    this.isDesktop = this.device === 'desktop' || this.device === 'hd';
    this.activePrebidWinningBids = {};
  }

  init() {
    window.googletag = window.googletag || { cmd: [] };
    setupHeaderBidding(this);

    if (this.isDesktop) {
      if (
        this.insertInto &&
        document.querySelectorAll(this.insertInto).length > 0
      ) {
        addSidebarAds(this);
      }
    } else if (
      this.inlineInto &&
      document.querySelectorAll(this.inlineInto).length > 0
    ) {
      addInlineMobileAds(this);
    }

    const slots = [];
    const aboveTheFoldSlots = [];
    const belowTheFoldSlots = [];
    const headerBiddingUnits = {
      prebid: [],
      aps: [],
      aboveTheFold: { prebid: [], aps: [] },
    };
    const ads = document.querySelectorAll(this.adSelector);
    const adObserver = getAdObserver(this, headerBiddingUnits, slots);
    const refreshObserver = getRefreshObserver(this, headerBiddingUnits, slots);

    window.googletag.cmd.push(() => {
      ads.forEach((ad) => {
        const {
          dfpId,
          dfpTargeting,
          dfpOutOfPage,
          dfpCollapse,
          dfpResponsive,
          dfpAboveTheFold,
        } = ad.dataset;

        const size = getSlotSizes(ad);

        let slot;

        if (dfpOutOfPage) {
          slot = window.googletag
            .defineOutOfPageSlot(`/${this.gamId}/${dfpId}`, size, ad.id)
            .addService(window.googletag.pubads());
        } else {
          slot = window.googletag
            .defineSlot(`/${this.gamId}/${dfpId}`, size, ad.id)
            .addService(window.googletag.pubads());
        }

        if (dfpTargeting) {
          slot.updateTargetingFromMap(getSlotLevelTargeting(dfpTargeting));
        }

        if (dfpCollapse && dfpCollapse === 'true') {
          slot.setCollapseEmptyDiv(true);
        }

        if (dfpResponsive) {
          const sizeMapping = getResponsiveMappings(dfpResponsive);
          if (sizeMapping) {
            const map = window.googletag.sizeMapping();
            sizeMapping.forEach((sizeMap) => {
              map.addSize(sizeMap.viewport, sizeMap.ad_sizes);
            });
            slot.defineSizeMapping(map.build());
          }
        }
        if (dfpAboveTheFold) {
          aboveTheFoldSlots.push(slot);
        } else {
          belowTheFoldSlots.push(slot);
          adObserver.observe(ad);
        }

        slots.push(slot);

        if (this.runPrebid) {
          if (
            this.prebidConfig[this.device] &&
            this.prebidConfig[this.device][dfpId]
          ) {
            const prebidUnit = { ...this.prebidConfig[this.device][dfpId] };
            prebidUnit.code = ad.id;
            prebidUnit.floors = {
              currency: 'GBP',
              modelVersion: 'Geo Floors',
              schema: {
                fields: ['mediaType', 'size'],
              },
              values: {
                'banner|*': this.GAMFloor,
              },
            };
            prebidUnit.pubstack.tags = [`floor: ${this.GAMFloor}`];
            headerBiddingUnits.prebid.push(prebidUnit);

            if (dfpAboveTheFold) {
              headerBiddingUnits.aboveTheFold.prebid.push(prebidUnit);
            }
          }
        }

        if (this.runUAM) {
          const apsSlot = {
            slotID: ad.id,
            slotName: `/${this.gamId}/${dfpId}`,
            sizes: size,
          };
          headerBiddingUnits.aps.push(apsSlot);
          if (dfpAboveTheFold) {
            headerBiddingUnits.aboveTheFold.aps.push(apsSlot);
          }
        }
      });
    });

    window.googletag.cmd.push(() => {
      debugInit(this, slots, headerBiddingUnits);

      // header bidding
      if (this.runPrebid || this.runUAM) {
        executeAuction(
          this,
          headerBiddingUnits.aboveTheFold,
          aboveTheFoldSlots,
        );
      }

      setServiceLevelTargeting(this);

      // Register ad event handlers
      window.googletag.pubads().addEventListener('slotRequested', (event) => {
        const { slot } = event;
        debugAdEvent(this, slot, 'fetched');
      });

      window.googletag.pubads().addEventListener('slotOnload', (event) => {
        const { slot } = event;
        debugAdEvent(this, slot, 'rendered');
      });

      window.googletag
        .pubads()
        .addEventListener('impressionViewable', (event) => {
          const { slot } = event;
          debugAdEvent(this, slot, 'impressionViewable');
          refreshAd(this, slot, refreshObserver);
        });

      window.googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        stickyAdSetup(this, event);
      });

      window.googletag.pubads().enableSingleRequest();
      window.googletag.pubads().disableInitialLoad();
      const ppid = getPublisherProvidedId(this);
      if (ppid) {
        window.googletag.pubads().setPublisherProvidedId(ppid);
      }
      window.googletag.enableServices();

      if (!this.runPrebid && !this.runUAM) {
        sendAdserverRequest(this, aboveTheFoldSlots);
      }
    });

    if (this.isDesktop) {
      addDesktopCommercialSlots(this);
    }

    this.initialized = true;
  }
}
