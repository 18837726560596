const setServiceLevelTargeting = (config) => {
  const {
    showNonPersonalisedAds,
    customVariables,
    testingCohort,
    GAMFloor,
    runAnonymised,
    device,
    inlineInto,
    desktopInlineInto,
  } = config;

  if (showNonPersonalisedAds === 'true') {
    window.googletag.pubads().setPrivacySettings({
      nonPersonalizedAds: true,
    });
    window.googletag.pubads().setTargeting('npa', 'true');
  } else {
    window.googletag.pubads().setPrivacySettings({
      nonPersonalizedAds: false,
    });
    window.googletag.pubads().setTargeting('npa', 'false');
  }

  if (customVariables) {
    Array.from(Object.entries(customVariables)).forEach((target) => {
      window.googletag.pubads().setTargeting(target[0], target[1]);
    });
  }

  if (!customVariables.url) {
    window.googletag
      .pubads()
      .setTargeting('url', window.location.pathname.substring(0, 40));
  }

  const queryString = new URLSearchParams(window.location.search);
  const testPage = (queryString && queryString.get) ? queryString.get('special') : false;
  if (testPage) { 
    googletag.cmd.push(() => {
      googletag.pubads().setTargeting('special', testPage);
    });
  }

  if (testingCohort) {
    window.googletag.pubads().setTargeting('testingCohort', testingCohort);
    window.googletag.pubads().setTargeting('pbstck_ab_test', testingCohort);
  }

  if (GAMFloor) {
    window.googletag.pubads().setTargeting('floor', GAMFloor.toString());
  }

  if (runAnonymised) {
    window.googletag
      .pubads()
      .setTargeting(
        'anonymised',
        localStorage.getItem('cohort_ids')
          ? JSON.parse(localStorage.getItem('cohort_ids'))
          : [],
      );
  }

  const inlineAdsDesktop =
    device === 'hd' && document.querySelector('.fixed-billboard');
  if (
    (inlineInto && document.querySelectorAll(inlineInto).length > 0) ||
    (inlineAdsDesktop &&
      desktopInlineInto &&
      document.querySelectorAll(desktopInlineInto).length > 0)
  ) {
    if (!customVariables.ads_count) {
      const numberInlineAds = String(
        document.querySelectorAll('.inlinead').length,
      );
      window.googletag.pubads().setTargeting('ads_count', numberInlineAds);
    }
  }

  const getText = (el) => {
    let textStr = '';
    for (let i = 0; i < el.childNodes.length; i += 1) {
      const node = el.childNodes[i];
      if (node.nodeType !== 8) {
        textStr += node.nodeType !== 1 ? node.nodeValue : getText(node);
      }
    }
    return textStr;
  };

  if (inlineInto && document.querySelectorAll(inlineInto).length > 0) {
    if (!customVariables.article_words) {
      const articleBody = document.querySelector(`${inlineInto}`);
      if (articleBody) {
        let words = getText(articleBody);
        words = words
          .split(' ')
          .filter((item) => item !== '' && item !== '\n' && item !== '\n\n');
        const numberArticleWords = String(Math.round(words.length / 10) * 10);
        window.googletag
          .pubads()
          .setTargeting('article_words', numberArticleWords);
      }
    }

    if (!customVariables.article_images) {
      const numberInlineImages = String(
        document.querySelectorAll(`${inlineInto} img`).length,
      );
      window.googletag
        .pubads()
        .setTargeting('article_images', numberInlineImages);
    }

    if (!customVariables.article_videos) {
      const articleVideoArray = [];
      articleVideoArray.push(
        ...Array.from(
          document.querySelectorAll(
            '.article .video_wrapper > iframe, .article .video_wrapper > video',
          ),
        ),
      );
      // Remove duplicate entries (in case more selectors need to be added to the above query)
      const numberInlineVideos = String([...new Set(articleVideoArray)].length);
      window.googletag
        .pubads()
        .setTargeting('article_videos', numberInlineVideos);
    }

    if (!customVariables.article_embeds) {
      const numberInlineEmbeds = String(
        document.querySelectorAll('.article iframe').length,
      );
      window.googletag
        .pubads()
        .setTargeting('article_embeds', numberInlineEmbeds);
    }
  }
};

const sendAdserverRequest = (config, slots) => {
  const { permutiveTimeout } = config;

  if (
    window.permutive &&
    window.permutive.readyWithTimeout &&
    permutiveTimeout
  ) {
    window.permutive.readyWithTimeout(
      () => {
        window.googletag.cmd.push(() => {
          window.googletag.pubads().refresh(slots);
        });
      },
      'realtime',
      permutiveTimeout,
    );
  } else {
    window.googletag.cmd.push(() => {
      window.googletag.pubads().refresh(slots);
    });
  }
};

export { setServiceLevelTargeting, sendAdserverRequest };
