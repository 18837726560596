const generateAd = (config, position) => {
  const { device, dynamicAdDesktop, dynamicAd, advertHeader } = config;

  const adUnit =
    device === 'hd' && dynamicAdDesktop ? dynamicAdDesktop : dynamicAd;

  const aboveTheFold = position === 0 ? 'data-dfp-above-the-fold="true"' : '';

  const adHTML = `
    <div class="insert_ad_column">
      <span class="advert_label">${advertHeader}</span>
      <div
        class="advert"
        data-dfp-id="${adUnit}"
        data-dfp-sizes="300x250, 300x600"
        data-dfp-collapse="true"
        ${aboveTheFold}
        data-dfp-targeting="lazyload=true,position=sidebar_${position}"
        id="div-ga-autoad_${adUnit}_${position}"
      ></div>
    </div>
  `;

  return adHTML;
};

const checkEmptySpace = (parentWrapper) => {
  const adArea = document.querySelector(parentWrapper);
  const adAreaStyle = window.getComputedStyle(adArea);
  let adAreaFree =
    parseFloat(adAreaStyle.height) - parseFloat(adAreaStyle.marginTop);

  for (let i = 0; i < adArea.children.length; i += 1) {
    const style =
      adArea.children[i].currentStyle ||
      window.getComputedStyle(adArea.children[i]);
    const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
    adAreaFree = adAreaFree - adArea.children[i].offsetHeight - margin;
  }

  if (adAreaFree > 700) {
    return adAreaFree;
  }
  return false;
};

const removeOverflowingSidebarAds = () => {
  const resizeObserver = new ResizeObserver((entries) => {
    entries.forEach((entry) => {
      const autoads = document.querySelectorAll(
        '.gn_sidebar > .autoad.collapsible',
      );

      [...autoads].reduce((acc, cur) => {
        const cumulativeHeight = acc + cur.offsetHeight;

        if (cumulativeHeight > entry.contentRect.height) {
          const removeSlot = window.googletag
            .pubads()
            .getSlots()
            .filter(
              (slot) =>
                slot.getSlotElementId() === cur.querySelector('.advert').id,
            );
          window.googletag.cmd.push(() => {
            window.googletag.destroySlots(removeSlot);
          });
          cur.remove();
        }

        return cumulativeHeight;
      }, 0);
    });
  });

  window.googletag.cmd.push(() => {
    resizeObserver.observe(document.querySelector('.gn_sidebar'));
  });
};

const addSidebarAds = (config) => {
  const {
    appendSidebarAds,
    insertInto,
    stickySidebar,
    insertTopMargin,
    stickyViewports,
  } = config;

  if (!appendSidebarAds) return;

  let emptySpaceLeft = checkEmptySpace(insertInto);
  if (!emptySpaceLeft) return;

  const columns = [];
  let columnHeight;

  do {
    if (
      stickySidebar &&
      emptySpaceLeft >= window.innerHeight * stickyViewports
    ) {
      columnHeight = window.innerHeight * stickyViewports;
    } else {
      columnHeight = Math.min(emptySpaceLeft, window.innerHeight + 600);
    }

    columns.push(columnHeight);
    emptySpaceLeft -= columnHeight;
  } while (columnHeight > window.innerHeight + 600);

  columns.forEach((column, index) => {
    const adArea = document.querySelector(insertInto);
    const newAd = document.createElement('div');
    newAd.classList.add('autoad', 'collapsible');
    newAd.style.height = `${column}px`;
    newAd.innerHTML = generateAd(config, index);
    if (index === 0) {
      newAd.style.marginTop = insertTopMargin;
    }
    if (stickySidebar) {
      const sidebarAd = newAd.querySelector('.insert_ad_column');
      sidebarAd.style.position = 'sticky';
      sidebarAd.style.top = '260px';
      sidebarAd.style.paddingTop = '10px';
      sidebarAd.style.paddingBottom = '300px';
    }

    adArea.appendChild(newAd);
  });

  removeOverflowingSidebarAds();
};

export default addSidebarAds;
