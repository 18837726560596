const getSlotSizes = (ad) => {
  if (ad.dataset.dfpSizes === 'fluid') {
    return 'fluid';
  }

  const sizes = [];
  const sizesData = ad.dataset.dfpSizes;

  if (sizesData) {
    const sizeGroups = sizesData.split(',');

    sizeGroups.forEach((size) => {
      const sizeSet = size.split('x');
      sizes.push([parseInt(sizeSet[0], 10), parseInt(sizeSet[1], 10)]);
    });
  } else {
    sizes.push([ad.width, ad.height]);
  }

  return sizes;
};

const getSlotLevelTargeting = (dfpTargeting) => {
  const targeting = {};
  const targetingGroups = dfpTargeting.split(',');

  targetingGroups.forEach((group) => {
    const [targetingKey, targetingValue] = group.split('=');
    targeting[targetingKey] = targetingValue;
  });

  targeting.refreshCount = '0';

  return targeting;
};

const getResponsiveMappings = (sizeMap) => {
  const sizeMappingDefaults = {
    'portable-leaderboard': [
      {
        viewport: [1260, 300],
        ad_sizes: [
          [728, 90],
          [970, 250],
          [1260, 110],
          [1260, 250],
        ],
      },
      {
        viewport: [980, 300],
        ad_sizes: [
          [728, 90],
          [970, 250],
        ],
      },
      { viewport: [750, 300], ad_sizes: [728, 90] },
      { viewport: [340, 300], ad_sizes: [320, 50] },
      { viewport: [0, 0], ad_sizes: [] },
    ],
    'portable-fishbowl': [
      {
        viewport: [980, 300],
        ad_sizes: [
          [320, 400],
          [728, 90],
          [970, 250],
        ],
      },
      {
        viewport: [750, 300],
        ad_sizes: [
          [320, 400],
          [728, 90],
        ],
      },
      { viewport: [0, 0], ad_sizes: [] },
    ],
    'portable-mpu-leader': [
      {
        viewport: [980, 300],
        ad_sizes: [
          [300, 250],
          [728, 90],
          [970, 250],
        ],
      },
      {
        viewport: [750, 300],
        ad_sizes: [
          [300, 250],
          [728, 90],
        ],
      },
      { viewport: [0, 0], ad_sizes: [] },
    ],
  };

  if (sizeMappingDefaults[sizeMap]) {
    return sizeMappingDefaults[sizeMap];
  }

  return null;
};

const getFloorPrice = (config) => {
  const { floorPrices, countryCode } = config;
  const {
    defaultFloorprice,
    usFloorprice,
    ukFloorprice,
    aucaFloorprice,
    euFloorprice,
  } = floorPrices;

  let floorPrice = defaultFloorprice;
  switch (countryCode) {
    case 'US':
      floorPrice = usFloorprice || 0;
      break;
    case 'GB':
    case 'UK':
      floorPrice = ukFloorprice || 0;
      break;
    case 'AU':
    case 'CA':
      floorPrice = aucaFloorprice || 0;
      break;
    case 'DE':
    case 'NL':
    case 'SE':
    case 'AT':
    case 'FR':
    case 'NO':
    case 'CH':
    case 'IE':
    case 'FI':
    case 'DK':
    case 'BE':
      floorPrice = euFloorprice || 0;
      break;
    default:
      floorPrice = defaultFloorprice || 0;
  }

  return floorPrice;
};

const insertAfter = (el, referenceNode) => {
  referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
};

const isBrandSafe = (config) => {
  const { customVariables } = config;
  if (customVariables.brand_safety || customVariables.ad_policies) {
    return false;
  }
  return true;
};

const getArticleContents = (config) => {
  const { inlineBetweenTextOnly, inlineInto, desktopInlineInto, isDesktop } =
    config;

  if (inlineBetweenTextOnly && !isDesktop) {
    return document.querySelectorAll(
      `${inlineInto} > *, ${inlineInto} section > *`,
    );
  }

  let inlineSelector = inlineInto;
  let notSelector =
    '*:not(section):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(script):not(.groups):not(.supporter-article-promo):not(.df-promo):not(aside):not(.internal-link):not(.poll_wrapper):not(.poll_ad_container)';

  if (isDesktop) {
    inlineSelector = desktopInlineInto;
    notSelector = `${notSelector}:not(figure.right + *):not(figure.right + * + *):not(figure.right + * + * + *):not(figure.left + *):not(figure.left + * + *):not(figure.left + * + * + *)`;
  }

  if (
    document.querySelectorAll(`${inlineSelector} > section:not(.summary):not(.synopsis)`)
      .length > 0
  ) {
    return document.querySelectorAll(
      `${inlineSelector} > ${notSelector}:not(.review_rating), ${inlineSelector} section:not(.summary):not(.synopsis) > ${notSelector}`,
    );
  }

  return document.querySelectorAll(
    `${inlineSelector} > ${notSelector}:not(.review_rating)`,
  );
};

const paragraphPlacementCheck = (config, i, array) => {
  const { isDesktop } = config;
  const pArray = Array.from(array).filter((el) => el.tagName === 'P');
  const lastPossiblePlacement = pArray[pArray.length - 2];

  if (isDesktop && array[i] === lastPossiblePlacement) {
    return false;
  }

  if (i < array.length - 1) {
    return array[i].tagName === 'P' && array[i + 1].tagName === 'P';
  }

  return false;
};

const chooseCohort = (cohorts) => {
  // Get total of cohohort split
  const random =
    Math.random() *
    cohorts.map((cohort) => cohort.split).reduce((a, c) => a + c);
  let sumOfSplit = 0;
  let testedCohort;

  for (let i = 0; i < cohorts.length; i += 1) {
    testedCohort = cohorts[i];
    sumOfSplit += testedCohort.split;
    if (sumOfSplit > random) break;
  }

  testedCohort.config.testingCohort = `${testedCohort.name}-${testedCohort.split}`;

  return testedCohort.config;
};

const stickyAdSetup = (config, event) => {
  const { slot } = event;
  const { stickyAd, desktopSticky, refreshAdsConfig } = config;
  const slotName = slot.getSlotElementId();

  if (
    (stickyAd && slotName.includes(stickyAd)) ||
    (desktopSticky && slotName.includes(desktopSticky))
  ) {
    const appFooter = document.querySelector('.app_footer');
    if (event.isEmpty) {
      window.googletag.cmd.push(() => {
        window.googletag.destroySlots([slot]);
        document.getElementById('sticky_leaderboard').remove();
        if (appFooter) {
          appFooter.classList.remove('sticky_leader_ad_padding');
        }
      });
    } else {
      document
        .getElementById('sticky_leaderboard')
        .classList.add('allow_click');
      if (appFooter) {
        appFooter.classList.add('sticky_leader_ad_padding');
      }
      if (
        Number.isInteger(event.advertiserId) &&
        refreshAdsConfig.refreshableAdvertIds.includes(event.advertiserId)
      ) {
        document
          .getElementById('sticky_leaderboard')
          .classList.add('show_wrapper');
      }
    }
  }
};

const getCookie = (name) => {
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  return cookieArray.find((cookie) => {
    const [cookieKey] = cookie.trim().split('=');
    return cookieKey === name;
  });
};

const setPublisherProvidedIdCookie = (id) => {
  const expiryDate = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  );

  if (id) {
    document.cookie = `brockmanPPID=${id};expires=${expiryDate.toUTCString()};`;
    return;
  }

  const consentUUIDCookie = getCookie('consentUUID');
  if (consentUUIDCookie) {
    const consentUUID = consentUUIDCookie.trim().split('=')[1];
    if (consentUUID) {
      document.cookie = `brockmanPPID=${consentUUID};expires=${expiryDate.toUTCString()};`;
    }
  }
};

const getPublisherProvidedId = (config) => {
  const { userExternalUuid } = config;

  const ppidCookie = getCookie('brockmanPPID');

  if (ppidCookie) {
    const ppidCookieValue = ppidCookie.trim().split('=')[1];

    if (userExternalUuid && userExternalUuid !== ppidCookieValue) {
      if (userExternalUuid !== ppidCookieValue) {
        setPublisherProvidedIdCookie(userExternalUuid);
      }
      return userExternalUuid;
    }

    return ppidCookieValue;
  }

  setPublisherProvidedIdCookie();
  return null;
};

export {
  getSlotSizes,
  getSlotLevelTargeting,
  getResponsiveMappings,
  getFloorPrice,
  insertAfter,
  isBrandSafe,
  getArticleContents,
  paragraphPlacementCheck,
  chooseCohort,
  stickyAdSetup,
  getPublisherProvidedId,
};
