/* eslint-disable no-console */

const getTargeting = (slot) => {
  const targeting = {};
  let targetingKeys = [];

  if (slot) {
    targetingKeys = slot.getTargetingKeys();
  } else {
    targetingKeys = window.googletag.pubads().getTargetingKeys();
  }

  targetingKeys.forEach((key) => {
    if (slot) {
      targeting[key] = slot.getTargeting(key);
    } else {
      targeting[key] = window.googletag.pubads().getTargeting(key);
    }
  });
  return targeting;
};

const debugMsg = (config, msg, obj) => {
  const { debug } = config;
  if (!debug) return;
  console.log(msg, obj);
};

const debugInit = (config, slots, headerBiddingUnits) => {
  const { debug } = config;
  if (!debug) return;
  const slotPaths = slots.map((slot) => slot.getAdUnitPath());
  console.groupCollapsed('BrockmanAds - init');
  console.log(`${slots.length} slots defined:`, slotPaths);
  console.log('headerBiddingUnits:', headerBiddingUnits);
  console.log('service level targeting', getTargeting());
  console.log('adConfig', config);
  console.groupEnd();
};

const debugAdEvent = (config, slot, eventType = 'Ad event') => {
  const { debug } = config;
  if (!debug) return;
  console.groupCollapsed(`${eventType} - ${slot.getSlotElementId()}`);
  console.log('adunitpath:', slot.getAdUnitPath());
  console.log('elementId:', slot.getSlotElementId());
  console.log('responseInformation:', slot.getResponseInformation());
  console.log('slot targeting', getTargeting(slot));
  console.groupEnd();
};

const debugRefresh = (config, slot) => {
  const { debug } = config;
  if (!debug) return;
  const refreshCount = parseInt(slot.getTargeting('refreshCount')[0], 10);
  if (!refreshCount) return;
  console.groupCollapsed(
    `Refreshing ad slot (${refreshCount}x): ${slot.getSlotElementId()}`,
  );
  console.log('adunitpath:', slot.getAdUnitPath());
  console.log('elementId:', slot.getSlotElementId());
  console.log('responseInformation:', slot.getResponseInformation());
  console.log('slot targeting', getTargeting(slot));
  console.groupEnd();
};

export { debugMsg, debugInit, debugAdEvent, debugRefresh };
