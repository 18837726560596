import { executeAuction } from './headerBidding';
import { debugMsg, debugRefresh } from './debug';

const getObserverBehaviour = (entry, runUAM, runPrebid, config, headerBiddingUnits, slots) => {
  const intersectingSlot = slots.filter(
    (slot) => slot.getSlotElementId() === entry.target.id,
  )[0];

  if (runUAM || runPrebid) {
    const refreshHeaderBiddingUnits = { prebid: [], aps: [] };
    if (runPrebid) {
      const prebidUnit = headerBiddingUnits.prebid.find(
        (unit) => unit.code === intersectingSlot.getSlotElementId(),
      );
      refreshHeaderBiddingUnits.prebid.push(prebidUnit);
    }

    if (runUAM) {
      const apsUnit = headerBiddingUnits.aps.find(
        (unit) => unit.slotID === intersectingSlot.getSlotElementId(),
      );
      refreshHeaderBiddingUnits.aps.push(apsUnit);
    }
    executeAuction(config, refreshHeaderBiddingUnits, [
      intersectingSlot,
    ]);
  } else {
    window.googletag.pubads().refresh([intersectingSlot]);
  }

  debugRefresh(config, intersectingSlot); 

}

const getAdObserver = (config, headerBiddingUnits, slots) => {
  const { runUAM, runPrebid, lazyloadDefaultSpacer, lazyloadSpacerMobile, device } =
    config;

  let adObserverMargins = lazyloadDefaultSpacer;
  if (device === 'mobile') {
    adObserverMargins = lazyloadSpacerMobile;
  }

  debugMsg(
    config,
    `Lazyload distance: ${adObserverMargins}px`,
    { adObserverMargins },
  );

  const adObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          adObserver.unobserve(entry.target);
          getObserverBehaviour(entry, runUAM, runPrebid, config, headerBiddingUnits, slots);
        }
      });
    },
    {
      root: null,
      rootMargin: `
        ${adObserverMargins}px 0px ${adObserverMargins}px 0px`,
    },
  );

  return adObserver;
};

const getRefreshObserver = (config, headerBiddingUnits, slots) => {
  const { refreshAdsConfig, runUAM, runPrebid } =
    config;
  const { threshold } =
    refreshAdsConfig;

  const refreshObserver = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          refreshObserver.unobserve(entry.target);
          getObserverBehaviour(entry, runUAM, runPrebid, config, headerBiddingUnits, slots);
        }
      });
    },
    {
      threshold: threshold
    },
  );

  return refreshObserver;
};

const getRefreshRate = (config, slot) => {
  const {
    device,
    refreshAdsConfig,
    stickyAd,
    runPrebid,
    activePrebidWinningBids,
  } = config;
  const {
    refreshDirect,
    mobileStickyRate,
    mobileRate,
    directMobileRate,
    desktopRate,
    teadsRate,
    conversantRate,
  } = refreshAdsConfig;

  let refreshRate =
    stickyAd && slot.getAdUnitPath().includes(stickyAd)
      ? mobileStickyRate
      : mobileRate;

  if (device === 'mobile' && refreshDirect) {
    refreshRate = directMobileRate;
  }

  if (device === 'desktop' || device === 'hd') {
    refreshRate = desktopRate;
  }

  if (runPrebid) {
    const winningBid = activePrebidWinningBids[slot.getSlotElementId()];

    if (winningBid) {
      if (winningBid.bidderCode === 'teads') {
        return teadsRate;
      }

      if (winningBid.bidderCode === 'conversant') {
        return conversantRate;
      }
    }
  }

  return refreshRate;
};

const refreshAd = (config, slot, refreshObserver) => {
  const { device, refreshAdsConfig } = config;
  const { enabled, refreshableAdvertIds, refreshDirect, maxRefreshes } =
    refreshAdsConfig;

  if (
    enabled &&
    !new URLSearchParams(window.location.search).has('google_preview')
  ) {
    let refreshCount = parseInt(slot.getTargeting('refreshCount')[0], 10);
    if (refreshCount >= maxRefreshes) {
      debugMsg(
        config,
        `Max refreshes (${maxRefreshes}) reached for ad slot: ${slot.getSlotElementId()}`,
        { refreshCount, maxRefreshes },
      );
      return;
    }

    const responseInformation = slot.getResponseInformation();
    const { advertiserId } = responseInformation;

    if (
      refreshableAdvertIds.includes(advertiserId) ||
      (device === 'mobile' && refreshDirect)
    ) {
      const refreshRate = getRefreshRate(config, slot);
      slot.setTargeting('ad_refresh', 'true');
      slot.setTargeting('ad_refresh_rate', `${refreshRate}`);

      setTimeout(() => {
        slot.setTargeting('refreshCount', `${(refreshCount += 1)}`);
        refreshObserver.observe(document.getElementById(slot.getSlotElementId()));
      }, refreshRate);
      debugMsg(
        config,
        `Making ${slot.getSlotElementId()} available for refresh in ${refreshRate}ms`,
      );
    } else {
      debugMsg(
        config,
        `Refresh blocked for ad slot: ${slot.getSlotElementId()}, advertiser id: ${advertiserId}`,
      );
    }
  }
};

export { getAdObserver, getRefreshObserver, refreshAd };
