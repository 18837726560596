import {
  isBrandSafe,
  getArticleContents,
  paragraphPlacementCheck,
} from './utils';
import { debugMsg } from './debug';

const addPrimisKeyValues = (adVariables) => {
  const { games, tags } = adVariables || {};
  let keyValues = '';

  if (games) {
    keyValues += `&kv_2=${games.join(',')}`;
  }

  if (tags) {
    keyValues += `&kv_1=${tags.join(',')}`;
  }

  return keyValues;
};

const generatePrimisPlaylist = (config) => {
  const {
    baseUri,
    primisPlaylistPlacementId,
    customVariables,
    ownedSite,
    childPublisher,
    primisStyling,
    videoPlaylistTitle,
  } = config;
  const cacheBuster = Math.round(new Date().getTime() / 1000);
  const pubUrl = baseUri;
  const primisPlacement = primisPlaylistPlacementId;

  const primisKeyValues = addPrimisKeyValues(customVariables);

  const scriptEl = document.createElement('script');
  scriptEl.setAttribute('type', 'text/javascript');
  scriptEl.setAttribute('language', 'javascript');

  if (!ownedSite && childPublisher) {
    scriptEl.src = `https://live.primis.tech/live/liveView.php?s=${primisPlacement}&playerApiId=primis_playlist_commercial_slot&pubUrl=${pubUrl}&schain=1.0,1!gamer.network,${childPublisher},1&cbuster=${cacheBuster}${primisKeyValues}`;
  } else {
    scriptEl.src = `https://live.primis.tech/live/liveView.php?s=${primisPlacement}&playerApiId=primis_playlist_commercial_slot&pubUrl=${pubUrl}&cbuster=${cacheBuster}${primisKeyValues}`;
  }

  const primisWrapper = document.createElement('div');
  primisWrapper.classList = 'primis_wrapper';
  primisWrapper.appendChild(scriptEl);

  const playlistWrapper = document.createElement('figure');
  playlistWrapper.classList = 'primis_playlist_wrapper';
  playlistWrapper.style.clear = 'both';

  if (primisStyling) {
    playlistWrapper.style.backgroundColor = '#f1f5f7';
    playlistWrapper.style.padding = '1px 16px 16px 16px';
    const playlistTitle = document.createElement('p');
    playlistTitle.className = 'section_title';
    playlistTitle.textContent = videoPlaylistTitle;
    playlistWrapper.appendChild(playlistTitle);
  }

  playlistWrapper.appendChild(primisWrapper);

  return playlistWrapper;
};

const videoPlaylistConditions = (config) => {
  const {
    videoPlaylist,
    primisPlaylistPlacementId,
    articleType,
    runAutoSlotsEverywhere,
    articlePublishedDate,
    customVariables,
    articleVideoAutoInject,
    videoPlaylistArticles,
    useArticleVideoLayout,
  } = config;

  if (useArticleVideoLayout) {
    return false;
  }

  const enableVideoPlaylist =
    videoPlaylist === true || primisPlaylistPlacementId !== '';

  const articleTypeNews = articleType === 'news' && !runAutoSlotsEverywhere;

  let articleOldContent = false;
  if (articlePublishedDate) {
    const articlePublishDate = new Date(articlePublishedDate);
    articleOldContent = articlePublishDate.getFullYear() < 2021;
  }

  if (
    customVariables.tags &&
    customVariables.tags.some((tag) => tag === 'digital-foundry')
  ) {
    debugMsg(
      config,
      'Video Playlist Conditions: FAILED - article tagged digital-foundry',
    );
    return false;
  }

  const existingVideoEmbed = document.querySelectorAll(
    'script[src*=live\\.primis\\.tech], script[data-cookies-src*=live\\.primis\\.tech]',
  ).length;

  if (existingVideoEmbed) {
    debugMsg(
      config,
      'Video Playlist Conditions: FAILED - existing primis embed found',
    );
    return false;
  }

  if (
    !enableVideoPlaylist ||
    !articleVideoAutoInject ||
    articleTypeNews ||
    (!articleOldContent && videoPlaylistArticles && !runAutoSlotsEverywhere) ||
    !isBrandSafe(config) ||
    !primisPlaylistPlacementId
  ) {
    debugMsg(config, 'Video Playlist Conditions: FAILED - No Primis placement');
    return false;
  }

  debugMsg(
    config,
    'Video Playlist Conditions: PASSED - Primis placement conditions met',
  );
  return true;
};

const generateSurvey = (config, surveyElement) => {
  const { runQuokkster, articleAutoPoll } = config;
  const quokksterSurvey = document.querySelector(
    '.poll_wrapper[data-fixed="false"]',
  );

  // Add Quokkster poll:
  // if Quokkster enabled, allowed on this article
  if (runQuokkster && articleAutoPoll && quokksterSurvey) {
    surveyElement.append(quokksterSurvey);
    quokksterSurvey.dataset.placed = true;
    debugMsg(config, 'ADDING QUOKKSTER POLL');
  }
};

const surveyConditions = (config) => {
  const { articleType, runQuokkster, articleAutoPoll } = config;

  // Check for manual Quokkster on page
  const manualQuokksterPoll = document.querySelector(
    '.poll_wrapper[data-fixed="true"]',
  );

  // If manual Quokkster on page then return false
  if (manualQuokksterPoll) {
    debugMsg(
      config,
      'Survey Conditions: FAILED - quokkster poll already on page',
    );
    return false;
  }

  // If quokkster is turned off, or article is of type news, or not a brand safe page,
  // or we're not auto placing polls, then return false.
  const isArticleTypeNews = articleType === 'news';
  if (isArticleTypeNews || !isBrandSafe(config) || !articleAutoPoll) {
    debugMsg(
      config,
      'Survey Conditions: FAILED - no quokkster poll injection',
      {
        isArticleTypeNews,
        isBrandSafe: isBrandSafe(config),
        articleAutoPoll,
        runQuokkster,
        manualQuokksterPoll: !!manualQuokksterPoll,
      },
    );
    return false;
  }

  // If Quokkster turned off then return false
  if (!runQuokkster) {
    debugMsg(config, 'Survey Conditions: FAILED - no quokkster poll settings');
    return false;
  }

  debugMsg(config, 'Survey Conditions: PASSED - quokkster poll conditions met');
  return true;
};

const addDesktopCommercialSlots = (config) => {
  const renderPlaylist = videoPlaylistConditions(config);
  const renderSurvey = surveyConditions(config);

  if (!renderPlaylist && !renderSurvey) {
    debugMsg(
      config,
      'Commmercial Slots: no injection, primis or pol conditions not met',
      {
        renderPlaylist,
        renderSurvey,
      },
    );
    return;
  }

  const getInsertPoints = (array) => {
    const insertPoints = [];
    let paragraphCount = 0;
    let previousInsertPoint = 0;

    array.forEach((contentElement, i) => {
      const isBetweenParagraph = paragraphPlacementCheck(config, i, array);

      if (contentElement.tagName === 'P') {
        paragraphCount += 1;
      }

      if (
        isBetweenParagraph &&
        contentElement.getBoundingClientRect().top +
          document.body.scrollTop -
          previousInsertPoint >
          window.innerHeight &&
        paragraphCount > 2
      ) {
        previousInsertPoint =
          contentElement.getBoundingClientRect().top + document.body.scrollTop;
        insertPoints.push(contentElement);
      }
    });

    return insertPoints;
  };

  const slot1 = document.createElement('div');
  const slot2 = document.createElement('div');
  slot1.style = 'clear: both';
  slot2.style = 'clear: both';
  slot1.classList.add('commercial-slot-1');
  slot2.classList.add('commercial-slot-2');

  let slot1InsertPoint;
  let slot2InsertPoint;

  const articleContents = getArticleContents(config);

  getInsertPoints(articleContents).forEach((insertPoint, i) => {
    if (i === 0) {
      slot1InsertPoint = insertPoint;
    } else if (i === 1) {
      slot2InsertPoint = insertPoint;
    }
  });

  if (renderPlaylist && renderSurvey) {
    if (slot1InsertPoint) {
      slot1InsertPoint.parentNode.insertBefore(
        slot1,
        slot1InsertPoint.nextSibling,
      );
      slot1.appendChild(generatePrimisPlaylist(config));

      debugMsg(
        config,
        'Commmercial Slots: adding slot 1 - primis, after:',
        slot1InsertPoint,
      );
    }
    if (slot2InsertPoint) {
      slot2InsertPoint.parentNode.insertBefore(
        slot2,
        slot2InsertPoint.nextSibling,
      );
      generateSurvey(config, slot2);

      debugMsg(
        config,
        'Commmercial Slots: adding slot 2 - poll, after:',
        slot2InsertPoint,
      );
    }
  } else if (renderPlaylist && !renderSurvey && slot1InsertPoint) {
    slot1InsertPoint.parentNode.insertBefore(
      slot1,
      slot1InsertPoint.nextSibling,
    );
    slot1.appendChild(generatePrimisPlaylist(config));

    debugMsg(
      config,
      'Commmercial Slots: adding slot 1 - primis, after:',
      slot1InsertPoint,
    );
  } else if (!renderPlaylist && renderSurvey && slot1InsertPoint) {
    slot1InsertPoint.parentNode.insertBefore(
      slot1,
      slot1InsertPoint.nextSibling,
    );
    generateSurvey(config, slot1);

    debugMsg(
      config,
      'Commmercial Slots: adding slot 1 - poll, after:',
      slot1InsertPoint,
    );
  } else if (!slot1InsertPoint && !slot2InsertPoint) {
    debugMsg(config, 'Commmercial Slots: No slot insert points');
  }
};

export {
  generatePrimisPlaylist,
  videoPlaylistConditions,
  surveyConditions,
  generateSurvey,
  addDesktopCommercialSlots,
};
