import {
  generatePrimisPlaylist,
  videoPlaylistConditions,
  surveyConditions,
  generateSurvey,
} from './commercial-slots';
import { insertAfter, getArticleContents } from './utils';

const generateInlineAd = (config, position) => {
  const {
    device,
    dynamicAd,
    lowLeaderboard,
    firstMobileAd,
    fixMobileAdHeight,
    mobileHalfpagePosition,
    advertHeader,
  } = config;

  const isMobile = device === 'mobile';
  const adUnit = isMobile ? dynamicAd : lowLeaderboard;
  let adClass = 'advert';
  let lazyloadOn = 'true';
  let adSizes = '';
  if (isMobile) {
    adSizes = '300x250';
  } else {
    adSizes += ', 728x90, 960x160, 960x250, 970x250';
    if (position === 0) {
      adSizes += ', 970x500';
    }
  }

  let dfpId = adUnit;
  let adId = `div-ga-autoad_${adUnit}_${position}`;
  let dfpAboveTheFold = '';

  const adStyle = '';
  if (firstMobileAd && position === 0 && isMobile) {
    adClass = `${adClass} mpu`;
    lazyloadOn = 'false';
    adSizes = '300x250, 320x400';
    dfpId = firstMobileAd;
    adId = firstMobileAd;
    dfpAboveTheFold = 'data-dfp-above-the-fold="true"';
  }
  if (fixMobileAdHeight && isMobile) {
    adClass = `${adClass} mobile_fixed_height`;
  }
  if (position === mobileHalfpagePosition && isMobile) {
    adClass = `${adClass} mobile_halfpage`;
    adSizes += ', 300x600';
  }
  const adHTML = `
    <span class="advert_label">${advertHeader}</span>
    <div
      class="${adClass}"
      style="${adStyle}"
      data-dfp-id="${dfpId}"
      data-dfp-sizes="${adSizes}"
      data-dfp-collapse="false"
      ${dfpAboveTheFold}
      data-dfp-targeting="lazyload=${lazyloadOn},position=inline_${position}"
      id="${adId}"
    ></div>
  `;

  return adHTML;
};

const textElementPlacementCheck = (config, position, array) => {
  const { inlineBetweenTextOnly } = config;
  if (!inlineBetweenTextOnly) {
    return true;
  }
  const textElements = ['P', 'OL', 'UL', 'TABLE'];
  if (position < array.length - 1) {
    return (
      textElements.includes(array[position].tagName) &&
      textElements.includes(array[position + 1].tagName)
    );
  }
  return false;
};

const addInlineMobileAds = (config) => {
  const { inlineAdsConfig, viewportMultiplierMobile, inlineInto } = config;
  const { lastAdTollerance, includeHeaderInCalc } = inlineAdsConfig;

  const aricleContentOffset = includeHeaderInCalc
    ? document.querySelector(inlineInto).getBoundingClientRect().top +
      document.documentElement.scrollTop
    : 0;

  const viewPortHeight = window.innerHeight * viewportMultiplierMobile;

  let { minAdvertSpacing } = inlineAdsConfig;
  minAdvertSpacing = minAdvertSpacing > 0 ? minAdvertSpacing : viewPortHeight;

  let offsetPixelCount = 0;
  let inlineCounter = 0;

  const newInlinePlacement = (position) => {
    const placement = document.createElement('div');
    placement.classList.add('inlinead');
    if (position !== 0) {
      placement.classList.add('collapsible');
    }

    if (position === 0) {
      placement.innerHTML = generateInlineAd(config, position);
    } else if (position === 1) {
      if (videoPlaylistConditions(config)) {
        placement.appendChild(generatePrimisPlaylist(config));
      } else {
        placement.innerHTML = generateInlineAd(config, position);
        // return null;
      }
    } else if (position === 2) {
      if (surveyConditions(config)) {
        generateSurvey(config, placement);
      } else {
        placement.innerHTML = generateInlineAd(config, position);
      }
    } else {
      placement.innerHTML = generateInlineAd(config, position);
    }

    offsetPixelCount = 0;
    inlineCounter += 1;
    return placement;
  };

  getArticleContents(config).forEach((contentElement, index, array) => {
    const canBePlacedHere = textElementPlacementCheck(config, index, array);
    offsetPixelCount += contentElement.offsetHeight;

    if (canBePlacedHere) {
      if (
        offsetPixelCount >= viewPortHeight - aricleContentOffset &&
        inlineCounter === 0
      ) {
        // Inserts the first placement on the first element that is past the viewports height
        const inlinePlacement = newInlinePlacement(inlineCounter);
        insertAfter(inlinePlacement, contentElement);
      } else if (inlineCounter === 1) {
        const pElAfterFishbowl = document.querySelector(
          '.inlinead:not(.collapsible) + p',
        );
        if (pElAfterFishbowl !== contentElement) {
          const inlinePlacement = newInlinePlacement(inlineCounter);
          if (inlinePlacement) {
            insertAfter(inlinePlacement, contentElement);
          }
        }
      } else if (offsetPixelCount >= minAdvertSpacing && inlineCounter > 1) {
        // Inserts placements after every collection of elements that adds to the minAdvertSpacing
        const inlinePlacement = newInlinePlacement(inlineCounter);
        insertAfter(inlinePlacement, contentElement);
      } else if (
        (array.length > 2 &&
          array.length === index + 2 &&
          offsetPixelCount >= minAdvertSpacing * lastAdTollerance) ||
        (array.length > 2 &&
          array.length === index + 2 &&
          inlineCounter === 0) ||
        (array.length <= 2 && array.length === index + 1 && inlineCounter === 0)
      ) {
        // Inserts a placement at the end of the content (before the last element) if there is a fair bit of space (indicated by lastAdTollerance)
        // Also inserts a placement (before the last element) if there are none on the page and we've reached the end of the content
        // Or at the very end if there is less than 2 elements.
        const inlinePlacement = newInlinePlacement(inlineCounter);
        insertAfter(inlinePlacement, contentElement);
      }
    }
  });
};

export { addInlineMobileAds };
