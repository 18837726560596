import { sendAdserverRequest } from './gam';

const executeAuction = (config, headerBiddingUnits, refreshSlots) => {
  const {
    runPrebid,
    runUAM,
    biddingTimeout,
    prebidTimeout,
    activePrebidWinningBids,
  } = config;

  const requestManager = {
    adserverRequestSent: false,
    aps: !runUAM,
    prebid: !runPrebid,
  };

  // when both APS and Prebid have returned, initiate ad request
  const biddersBack = () => {
    if (requestManager.aps && requestManager.prebid) {
      if (requestManager.adserverRequestSent === true) return;
      requestManager.adserverRequestSent = true;
      sendAdserverRequest(config, refreshSlots);
    }
  };

  // sends bid request to APS and Prebid
  const requestHeaderBids = () => {
    if (runUAM) {
      window.apstag.fetchBids(
        {
          slots: headerBiddingUnits.aps,
        },
        // eslint-disable-next-line no-unused-vars
        (bids) => {
          window.apstag.setDisplayBids();
          requestManager.aps = true; // signals that APS request has completed
          biddersBack(); // checks whether both APS and Prebid have returned
        },
      );
    }

    if (runPrebid) {
      refreshSlots.forEach(
        (slot) => delete activePrebidWinningBids[slot.getSlotElementId()],
      );

      window.pbjs.que.push(() => {
        window.pbjs.requestBids({
          timeout: prebidTimeout,
          adUnits: headerBiddingUnits.prebid,
          bidsBackHandler: () => {
            window.pbjs.setTargetingForGPTAsync();

            requestManager.prebid = true; // signals that Prebid request has completed
            biddersBack(); // checks whether both APS and Prebid have returned
          },
        });
      });
    }
  };

  // initiate bid request
  requestHeaderBids();

  // set failsafe timeout
  window.setTimeout(() => {
    if (requestManager.adserverRequestSent === true) return;
    requestManager.adserverRequestSent = true;
    sendAdserverRequest(config, refreshSlots);
  }, biddingTimeout);
};

const setupHeaderBidding = (config) => {
  const {
    runPrebid,
    runUAM,
    pubUAM,
    ownedSite,
    runAnonymised,
    activePrebidWinningBids,
  } = config;

  if (runPrebid) {
    window.pbjs = window.pbjs || {};
    window.pbjs.que = window.pbjs.que || [];

    window.pbjs.que.push(() => {
      window.pbjs.onEvent('bidWon', (bid) => {
        activePrebidWinningBids[bid.adUnitCode] = bid;
      });

      window.pbjs.aliasBidder('rubicon', 'shemedia');

      window.pbjs.bidderSettings = {
        amx: {
          storageAllowed: true,
        },
        criteo: {
          storageAllowed: true,
        },
        teads: {
          storageAllowed: true,
        },
        triplelift: {
          storageAllowed: true,
        },
        onetag: {
          storageAllowed: true,
        },
        ix: {
          storageAllowed: true,
        },
        shemedia: {
          // eslint-disable-next-line no-unused-vars
          bidCpmAdjustment: (bidCpm, bid) => bidCpm * 0.48,
        },
      };

      window.pbjs.setConfig({
        useBidCache: true,
        priceGranularity: {
          buckets: [
            {
              precision: 2,
              max: 3,
              increment: 0.01,
            },
            {
              max: 9,
              increment: 0.02,
            },
            {
              max: 20,
              increment: 0.05,
            },
            {
              max: 30,
              increment: 0.1,
            },
            {
              max: 99,
              increment: 0.5,
            },
          ],
        },
        currency: {
          adServerCurrency: 'GBP',
          granularityMultiplier: 1,
          defaultRates: { GBP: { USD: 1.38, EUR: 1.17 } },
        },
        floors: {},
        realTimeData: {
          auctionDelay: 50,
          dataProviders: [
            {
              name: 'permutive',
              waitForIt: true,
              params: {
                acBidders: ['appnexus', 'pubmatic', 'openx'],
              },
            },
          ],
        },
        userSync: {
          userIds: [
            {
              name: 'pubCommonId',
              storage: {
                type: 'cookie',
                name: `"_pubcid"`,
                expires: 365,
              },
            },
            {
              name: 'id5Id',
              params: {
                partner: 978,
              },
              storage: {
                type: 'html5',
                name: 'id5id',
                expires: 90,
                refreshInSeconds: 8 * 3600,
              },
            },
          ],
          auctionDelay: 50,
          filterSettings: {
            iframe: {
              bidders: '*',
              filter: 'include',
            },
          },
        },
      });

      if (typeof __tcfapi === 'function') {
        window.pbjs.setConfig({
          consentManagement: {
            gdpr: {
              cmpApi: 'iab',
              timeout: 8000,
              defaultGdprScope: true,
              rules: [
                {
                  purpose: 'storage',
                  enforcePurpose: true,
                  enforceVendor: true,
                  vendorExceptions: ['permutive'],
                },
                {
                  purpose: 'basicAds',
                  enforcePurpose: true,
                  enforceVendor: true,
                  vendorExceptions: [],
                },
              ],
            },
          },
        });
      }

      if (runAnonymised) {
        window.pbjs.setBidderConfig({
          bidders: ['appnexus'],
          config: {
            ortb2: {
              user: {
                keywords: localStorage.getItem('cohort_ids')
                  ? JSON.parse(localStorage.getItem('cohort_ids'))
                      .map((x) => `perid=${x}`)
                      .join(', ')
                  : [],
              },
            },
          },
        });
        window.pbjs.setBidderConfig({
          bidders: ['criteo', 'pubmatic'],
          config: {
            ortb2: {
              user: {
                data: [
                  {
                    name: 'anonymised.io',
                    ext: {
                      segtax: 1000,
                    },
                    segment: localStorage.getItem('cohort_ids')
                      ? JSON.parse(localStorage.getItem('cohort_ids')).map(
                          (x) => ({ id: x }),
                        )
                      : [],
                  },
                ],
              },
            },
          },
        });
      }
    });
  }

  if (runUAM) {
    const schainNode = !ownedSite
      ? [
          {
            asi: 'gamer.network', // Populate with the canonical domain of the advertising system where the seller.JSON file is hosted.
            sid: '22392450815', // The identifier associated with the seller or reseller account within your advertising system
            hp: 1, // 1 or 0, whether this node is involved in the payment flow
          },
        ]
      : [];

    let amazonInitParams = {
        pubID: pubUAM,
        adServer: 'googletag',
        simplerGPT: false,
        params: {
          us_privacy: ''
        },
        gdpr: {
          cmpTimeout: 5000
        },
        schain: {
          complete: 1, // Integer 1 or 0 indicating if all preceding nodes are complete
          ver: '1.0', // Version of the spec used
          nodes: schainNode,
        }
    }
    if (window.__uspapi) {
        window.__uspapi('getUSPData', 1, function(res) {
            if (res.uspString) {
                amazonInitParams.params = { us_privacy: res.uspString }
            }
            window.apstag.init(amazonInitParams);
        });
    } else {
      window.apstag.init(amazonInitParams);    
    }
    
  }
};

export { setupHeaderBidding, executeAuction };
